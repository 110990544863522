<template>
  <Layout>
    <div class="main-pay-success">
      <div class="base-width">
        <!-- 返回 -->
        <BaseButton style-type='light' class="btn-back" :hover='false' @click="$router.back()">
          <span>返回</span>
          <img src="@/assets/images/icon-back-2.png">
        </BaseButton>
        <!-- 支付状态 -->
        <div class="status s26 color-2 font-medium flex-center" v-ani.fade-up>
          <img src="@/assets/images/icon-success.png">
          <span>支付成功</span>
        </div>
        <!-- 订单商品列表 -->
        <OrderProductList :goodsArr="goodsArr"></OrderProductList>
        <!-- 按钮 -->
        <div class="flex-center btns" v-ani.fade-up>
          <BaseButton tag='router-link' :to='`/order-detail?order_id=${order_id}`' size='large'>查看订单详情</BaseButton>
          <BaseButton tag='router-link' to='/' size='large' style-type='light'>返回首页</BaseButton>
        </div>
        <!-- 其他热推 -->
        <div class="md-prod-recommend">
          <div class="c-title-2 s22" v-ani.fade-up>其他热推</div>
          <div class="list flex-wrap">
            <template v-for="item in mockList">
              <ProductItem :value="item" :key="item.goods_id" v-ani.fade-up />
            </template>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import { OrderPayDetailApi } from "@/request/api/order";
import { SelectedProductsApi } from "@/request/api/shop";
export default {
  created() {
    this.order_sn = this.$route.query.order_sn;
    this.order_id = this.$route.query.order_id;
    OrderPayDetailApi({ order_sn: this.order_sn }).then(({ status, data }) => {
      if (status == 200) {
        this.goodsArr = data;
      }
    })
    // 精选商品
    SelectedProductsApi({ p: 1, page_num: 6 }).then(({ status, data }) => {
      if (status == 200) {
        this.mockList = data.list;
      }
    })
  },
  data() {
    return {
      order_sn: "",
      order_id: "",
      goodsArr: [],
      mockList: [
        {
          id: 1,
          img: require('@/assets/images/prod-1.jpg'),
          title: '水性钢结构防护彩钢瓦漆铁红中灰金属防锈底漆水性工业漆',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 2,
          img: require('@/assets/images/prod-2.jpg'),
          title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 3,
          img: require('@/assets/images/prod-3.jpg'),
          title: '佛山瓷砖800x800客厅地砖全瓷通体大理石地板砖',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 4,
          img: require('@/assets/images/prod-4.jpg'),
          title: '幻彩漆金属肌理漆天鹅绒内墙漆艺术涂料',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 5,
          img: require('@/assets/images/prod-1.jpg'),
          title: '水性钢结构防护彩钢瓦漆铁红中灰金属防锈底漆水性工业漆',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }, {
          id: 6,
          img: require('@/assets/images/prod-2.jpg'),
          title: '水上乐园挂壁式泳池防水水下灯LED水底灯',
          unit: '桶',
          price: '¥280.00',
          sales: '150万人已购买',
          place: '广东-广州',
          data1: '官方店',
          data2: '45天账期',
          shop: {
            name: '易筑官方旗舰店',
            icon: require('@/assets/images/icon-89.png')
          }
        }
      ]
    };
  },
};
</script>

<style lang="scss" scoped>
.main-pay-success {
  padding-top: .4rem;
  padding-bottom: .4rem;

  .btn-back {
    width: 1rem;

    img {
      width: .13rem;
      margin-left: .04rem;
    }
  }

  .status {
    margin: .04rem 0 .36rem;

    img {
      width: .4rem;
      margin-right: .13rem;
    }
  }

  .btns {
    margin-top: .39rem;

    .base-button {
      min-width: 2rem;
      margin: 0 .19rem 0 0;

      &:last-of-type {
        margin-right: 0;
      }
    }
  }

  .md-prod-recommend {
    margin-top: .38rem;

    .list {
      margin-top: .15rem;
      margin-bottom: -.2rem;
      flex-wrap: wrap;
    }

    .c-product-item {
      width: 2.5rem;
      height: auto;
      margin-right: .2rem;
      margin-bottom: .2rem;

      &:nth-of-type(6n) {
        margin-right: 0;
      }

      ::v-deep .goods .tit {
        margin-top: .12rem;
      }
    }
  }
}
</style>